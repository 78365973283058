import ScrollAnimation from "./components/ScrollAnimation";
import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";

export default function App() {
  return (
    <>
      <Canvas>
        <Suspense fallback={null}>
          <ScrollAnimation />
        </Suspense>
      </Canvas>
    </>
  );
}
