import heroImage from "../images/template.png";
import Projects from "./Projects";
import Footer from "./Footer";
import Sky from "../images/space.png";
import Library from "../images/read.png";
import DevOps from "../images/dev.png";
import Service from "../images/services.png";
import Beauty from "../images/metiss.png";
import Happy from "../images/sqvt.png";

export default function Main() {
  //"https://formsubmit.co/el/haxume"
  return (
    <div className="main pl-8">
      <section className="presentation">
        <h3 className="text-6xl lg:text-8xl">Ludo code !</h3>
        <h1 className="text-3xl lg:text-6xl">
          Professional Web Development Solutions
        </h1>
        <div className="grid grid-cols-1 mt-8 lg:mt-60 lg:grid lg:grid-cols-2 items-center justify-items-start lg:justify-items-center">
          <img className="w-32 mb-8 lg:w-1/4 rounded-lg" src={heroImage} />
          <p className="pr-20 text-2xl text-justify">
            I help you transform your business with innovative web and mobile
            solutions. I meet your needs for the creation of your site, mobile
            application and secure online payment. How can you improve your
            credibility, customer experience, and reach on the web? How to
            automate your processes? Get in touch today.
            {/* Je vous aide à transformer votre entreprise avec des solutions Web
            et mobiles innovantes. Je réponds à vos besoins pour la création de
            votre site, application mobile et paiement en ligne sécurisé.
            Comment améliorer votre crédibilité, votre expérience client et
            votre portée sur le web ? Comment automatiser vos processus ? Prenez
            contact dès aujourd'hui. */}
            {/* Ludo, développeur web passionné, curieux et dynamique. Expérience en
            HTML, CSS et JavaScript. Motivé par le challenge de créer vos
            demandes colorés et personnalisés. Portfolio en ligne disponible. */}
          </p>
        </div>
      </section>

      <section className="portfolio w-80 lg:text-right lg:w-full">
        <h2 className="text-6xl lg:text-8xl">My Portfolio</h2>
        <h3 className="text-3xl lg:text-6xl">My latest projects</h3>

        <div className="project grid grid-cols-2 gap-6 lg:gap-12 my-10 lg:my-20">
          <Projects
            img={Sky}
            alt="Photo of the space"
            link={"https://skypictures.netlify.app/"}
            linkText={"Click to visit"}
            title={"In the sky!"}
            text={"Javascript OOP, API, SASS, GitHub"}
          />
          <Projects
            img={Library}
            alt="un lecteur"
            link={"https://urlibrary.netlify.app/"}
            linkText={"Click to visit"}
            title={"My library"}
            text={"REACT, SASS, STORAGE, API"}
          />
          <Projects
            img={DevOps}
            alt="a dev"
            link={"https://ludo-code.com/"}
            linkText={"Click to visit"}
            title={"DevOps"}
            text={"TERRAFORM, AWS S3, GITHUB ACTION, THREE"}
          />
          <Projects
            img={Service}
            alt="Digital Services"
            link={"https://aod.cyclic.app/"}
            linkText={"Click to visit"}
            title={"My services"}
            text={"MERN, TAILWIND, GOOGLE AUTH, CRUD"}
          />
        </div>

        <h3>My clients projects</h3>

        <div className="project grid grid-cols-2 gap-12 mt-10 lg:my-40">
          <Projects
            img={Beauty}
            alt="nail"
            linkText={"Click to visit"}
            link={"https://metiissnail.netlify.app/"}
            title={"Beauty"}
            text={"HTML, CSS, JV"}
          />
          <Projects
            img={Happy}
            alt="happy people"
            linkText={"Click to visit"}
            link={"https://mllehappiness.netlify.app/"}
            title={"Happy"}
            text={"REACT, SASS"}
          />
        </div>
      </section>

      <section className="contact">
        <h2 className="text-6xl lg:text-8xl">Contact</h2>
        <form
          className="mt-4 lg:mt-16 grid grid-cols-3 lg:grid-cols-1 text-lg lg:text-2xl gap-2"
          action="https://formsubmit.co/f4c0b661879d47be04a3bb62e4bbe4ac"
          method="POST"
        >
          <label className="w-10">Name</label>
          <input
            className="bg-transparent border-b-2 col-span-2 -ml-4 mb-2 lg:mb-10 w-2/3 lg:w-4/5 ease-linear duration-300 focus:border-amber-400 outline-none focus:w-4/5 lg:focus:w-full focus:border-b-4"
            type="text"
            name="name"
            required
          />
          <label>Email</label>
          <input
            className="bg-transparent border-b-2 col-span-2 -ml-4 mb-2 lg:mb-10  w-2/3 lg:w-4/5 ease-linear duration-300 focus:border-amber-400 outline-none  focus:w-4/5 lg:focus:w-full focus:border-b-4"
            type="email"
            name="email"
            required
          />
          <label>Message</label>
          <textarea
            required
            className="bg-transparent border-b-2 col-span-2 -ml-4 mb-2 lg:mb-10 w-2/3 lg:w-4/5 ease-linear duration-300 focus:border-amber-400 outline-none  focus:w-4/5 lg:focus:w-full focus:border-b-4"
            name="message"
          />
          <input
            type="hidden"
            name="_next"
            value="http://localhost:3000/pages/thankyou.html"
          />
          <button
            className="btn text-amber-50 text-xs lg:text-base  mb-10 w-2/3 lg:w-4/5 ease-linear duration-300 hover:border-amber-400 hover:text-amber-400 hover:border-2"
            type="submit"
          >
            SEND
          </button>
        </form>
      </section>
      <Footer />
    </div>
  );
}
