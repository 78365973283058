import { useTexture, useIntersect, useScroll } from "@react-three/drei";
import texture from "../textures/3.jpg";
import { useFrame, useThree } from "@react-three/fiber";
import { useMemo, useRef } from "react";
import { NearestFilter } from "three";
import * as THREE from "three";
import gsap from "gsap";

export default function Objects() {
  const { height, width } = useThree((state) => state.viewport);
  const coneRef = useRef();

  // check if screen width is smaller than 600px
  const isSmartScreen = width < 700 + "px";
  return (
    <>
      <directionalLight
        isSmallScreenposition={[1, 1, 0]}
        color="#ffffff"
        intensity={1}
      />

      <Item position={[0, [-6 * -0.2], 0]} rotation={[0, Math.PI * 0.25, 0]}>
        <torusGeometry args={[1, 0.4, 16, 60]} />
      </Item>

      <Item position={[0, [-6 * 1], 0]} rotation={[0, Math.PI * 0.25, 0]}>
        <coneGeometry args={[1, 2, 32]} />
      </Item>

      <Item position={[0, [-6 * 3.6], 0]} rotation={[0, Math.PI * 0.25, 0]}>
        <torusKnotGeometry args={[0.8, 0.35, 100, 16]} />
      </Item>
    </>
  );
}

function Item({ position, children }) {
  const textureImg = useTexture({
    map: texture,
  });

  const gradientTexture = textureImg.map;
  gradientTexture.magFilter = THREE.NearestFilter;

  const visible = useRef();
  const ref = useIntersect((isVisible) => (visible.current = isVisible));
  const [xRandomFactor, yRandomFactor] = useMemo(
    () => [(0.5 - Math.random()) * 0.5, (0.5 - Math.random()) * 0.5],
    []
  );

  //scroll set for animation
  let currentSection = 0;
  const scroll = useScroll();

  // useFrame(({ clock }, delta) => {
  //   const elapsedTime = clock.getElapsedTime();
  //   ref.current.rotation.x = elapsedTime * xRandomFactor;
  //   ref.current.rotation.y = elapsedTime * yRandomFactor;
  //   const scale = THREE.MathUtils.damp(
  //     ref.current.scale.x,
  //     visible.current ? 1 : 0.5,
  //     0,
  //     delta
  //   );
  //   ref.current.scale.set(scale, scale, scale);

  useFrame((state, delta) => {
    // const elapsedTime = clock.getElapsedTime();
    ref.current.rotation.x += delta * 0.1;
    ref.current.rotation.y += delta * 0.15;
    const scale = THREE.MathUtils.damp(
      ref.current.scale.x,
      visible.current ? 1 : 0.5,
      0,
      delta
    );
    ref.current.scale.set(scale, scale, scale);

    // animation on the object on scroll
    const section = Number(scroll.offset.toFixed(1));
    const newSection = Math.round(section * 2);
    if (newSection !== currentSection) {
      currentSection = newSection;
      gsap.to(ref.current.rotation, {
        duration: 2,
        ease: "power2.inOut",
        x: "+=6",
        y: "+=3",
        z: "+=1.5",
      });
    }
  });

  return (
    <mesh ref={ref} position={position}>
      {children}
      <meshToonMaterial gradientMap={gradientTexture} />
    </mesh>
  );
}

//
// import { useThree } from "@react-three/fiber";
// import texture from "../textures/3.jpg";
// import { useTexture, useScroll } from "@react-three/drei";
// import { useCallback, useRef, useState } from "react";
// import useRefs from "react-use-refs";
// import * as THREE from "three";
// // import { NearestFilter } from 'three'
// import { useFrame } from "@react-three/fiber";
// import gsap from "gsap";

// export default function Objects() {
//   //
//   const viewport = useThree((state) => state.viewport);

//   const {
//     size: { width, height },
//   } = useThree();
//   let ratio = width / height;
//   const scale = Math.min(1, ratio);

//   let conePosition = -2;
//   ratio < 1 ? (conePosition = -0.5) : conePosition;

//   // let objectPosition = -2;
//   // ratio < 1 ? (conePosition = -0.5) : conePosition;

//   //
//   //scroll set for animation
//   let currentSection = 0;
//   const scroll = useScroll();

//   const directionalLight = useRef();
//   const propsTexture = useTexture({
//     map: texture,
//   });

//   const gradientTexture = propsTexture.map;
//   gradientTexture.magFilter = THREE.NearestFilter;

//   const objectsDistance = 6;
//   const [torusRef, coneRef, toruskRef] = useRefs();
//   const objectsRef = [torusRef, coneRef, toruskRef];

//   //save in data objectLength
//   const [objectLength, setObjectLength] = useState(objectsRef.length);

//   useFrame((state, delta) => {
//     for (const object of objectsRef) {
//       object.current.rotation.y += delta * 0.1;
//       object.current.rotation.x += delta * 0.15;
//     }

//     //animation on the object on scroll
//     const section = Number(scroll.offset.toFixed(1));
//     const newSection = Math.round(section * 2);
//     if (newSection != currentSection) {
//       currentSection = newSection;
//       gsap.to(objectsRef[currentSection].current.rotation, {
//         duration: 2,
//         ease: "power2.inOut",
//         x: "+=6",
//         y: "+=3",
//         z: "+=1.5",
//       });
//     }
//   });

//   return (
//     <>
//       <directionalLight
//         ref={directionalLight}
//         position={[1, 1, 0]}
//         color="#ffffff"
//         intensity={1}
//       />
//       <mesh
//         scale={[scale, scale, scale]}
//         ref={torusRef}
//         position-x={0}
//         rotation-y={Math.PI * 0.25}
//         position-y={[-objectsDistance * -0.2]}
//       >
//         <torusGeometry args={[1, 0.4, 16, 60]} />
//         <meshToonMaterial gradientMap={gradientTexture} />
//       </mesh>

//       <mesh
//         scale={[scale, scale, scale]}
//         ref={coneRef}
//         position-x={conePosition}
//         rotation-y={Math.PI * 0.25}
//         position-y={[-objectsDistance * 1]}
//       >
//         <coneGeometry args={[1, 2, 32]} />
//         <meshToonMaterial gradientMap={gradientTexture} />
//       </mesh>

//       <mesh
//         scale={[scale, scale, scale]}
//         ref={toruskRef}
//         position-x={0}
//         rotation-y={Math.PI * 0.25}
//         position-y={[-objectsDistance * 3.5]}
//       >
//         <torusKnotGeometry args={[0.8, 0.35, 100, 16]} />
//         <meshToonMaterial gradientMap={gradientTexture} />
//       </mesh>
//     </>
//   );
// }
